import Translate from "../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../common/CustomCellRender";
import { isRtl as validateRtl } from "../../../common/Utils/commonFunction";
import {
  CustomDueDateCellRender,
  CustomPriorityCellRender,
  CustomProgressBarCellRender,
  CustomStatusCellRender,
  CustomTeamCellRender,
} from "../Components/utils";

const isRtl = validateRtl();

export const getSelfAssignedTaskColumns = (
  customSrCellRender,
  customActionCellRender,
  isSort = true
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
      flex: 0.15,
    },
    {
      field: "taskNumber",
      headerName: Translate("manageTask:taskIdTitle"),
      flex: 0.42,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(
          params?.row?.taskNumber,
          "",
          params?.row?.hasPinned,
          params?.row?.hasFlagged,
          params?.row?.riskFactor,
          params?.row?.taskStatus !== "Completed" &&
            !params?.row?.taskNumber.startsWith("S")
        ),
    },
    {
      field: "projectId",
      headerName: Translate("manageTask:projectTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params?.row?.projectDetails) {
          return (
            <div className="projectFieldParent">
              <span className="projectNumberField">
                {params?.row?.projectDetails?.projectNumber}
              </span>

              <span
                tabindex="0"
                data-toggle="tooltip"
                data-placement="bottom"
                title={params?.row?.projectDetails?.title}
                class="d-inline-block ellipsisEventCard "
                style={{ flex: "80%", width: "100%" }}
              >
                <div className="projectNameField">
                  {params?.row?.projectDetails?.title}
                </div>
              </span>
            </div>
          );
        } else {
          return <span>{`Independent task`}</span>;
        }
      },
    },

    /*   {
      field: "type",
      headerName: Translate("manageTask:taskTypeTitle"),
      flex: 0.26,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomTaskTypeCellRender,
    }, */
    {
      field: "title",
      headerName: Translate("manageTask:taskNameTitle"),
      flex: 0.4,
      sortable: isSort,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.title),
    },

    {
      field: "company.name",
      headerName: Translate("manageTask:companyNameTitle"),
      flex: isRtl ? 0.47 : 0.41,
      sortable: isSort,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.company?.name),
    },
    {
      field: "dueDate",
      headerName: Translate("manageTask:dueDateAndTimeTitle"),
      flex: 0.28,
      sortable: isSort,
      disableColumnMenu: true,
      renderCell: CustomDueDateCellRender,
    },
    {
      field: "teamTitle",
      headerName: Translate("manageTask:teamTitle"),
      flex: 0.33,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomTeamCellRender,
    },
    {
      field: "priority",
      headerName: Translate("manageTask:priorityTitle"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomPriorityCellRender,
    },
    {
      field: "taskStatus",
      headerName: Translate("manageTask:statusTitle"),
      flex: isRtl ? 0.3 : 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(CustomStatusCellRender(params)),
    },
    {
      field: "progressBar",
      headerName: Translate("manageTask:progressBar"),
      flex: isRtl ? 0.26 : 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomProgressBarCellRender,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: isRtl ? 0.1 : 0.2,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};
